import React from 'react';

import illu from '../../../images/illu-attribution-chasing.svg';

const Summary = () => {
  return (
    <div className="max-w-7xl mx-auto pb-8 pt-8 sm:pt-0 px-4 sm:px-6 lg:pb-24 lg:px-8">
      <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div className="lg:col-start-1">
          <h3 className="text-2xl font-extrabold text-gray-900 font-title tracking-tight sm:text-3xl">
            Résultat : <span className="text-green-brand">62%</span> des visites
            venant de l'affiliation sont des visites finalistes des parcours de
            conversion*
          </h3>
          <p className="mt-3 text-lg text-gray-500">
            Le marché de l'affiliation s'est donc adapté au modèle d'attribution
            historique de l'affiliation et cela a laissé place à une course sans
            fin où l'affilié cherche à maximiser ses chances d'être le tout
            dernier affilié : extension navigateur qui se déclenche sur la page
            panier, bons de réduction, retargeting…
          </p>
          <p className="text-xs text-gray-500 mt-4">
            *Benchmark janvier 2019 Easyence
          </p>
        </div>

        <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-2">
          <img className="relative mx-auto" src={illu} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Summary;
